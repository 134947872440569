@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: "Plus Jakarta Sans", sans-serif;
    @apply text-normal text-light-black bg-main-white;
}

* {
    -webkit-tap-highlight-color: transparent;
}
@layer base {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
        -moz-appearance: textfield; /* Firefox */
    }
    .effect {
        @apply transition-all opacity-100 hover:opacity-70;
    }
    .btn-red-gradient {
        background: linear-gradient(90deg, #dd727d 0%, #582b2f 100%);
        @apply text-normal font-semibold rounded-[100px] text-white h-[47px] px-[32px] opacity-100 hover:opacity-80 transition-all;
    }
    .text-red-gradient {
        background: linear-gradient(90deg, #dd727d 0%, #834047 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    /* font  */
    .heading {
        @apply text-md md:text-xxl font-extrabold text-white;
    }
    .heading-2 {
        @apply text-xl font-bold;
    }
    .heading-1 {
        @apply text-base md:text-lg font-bold;
    }
    .title {
        @apply text-main-black text-lg font-bold;
    }
    .subtext-1 {
        @apply text-normal text-light-black;
    }
    .subtext-2 {
        @apply text-normal;
    }
}

@layer components {
    /* nav */
    .nav-wrap {
        border-bottom: 1px solid #e8e8e8;
        background: #fbfbfb;
    }
    .nav-items-wrap li a {
        @apply font-normal hover:font-semibold transition-all relative;
    }
    .nav-items-wrap li a:hover {
        @apply border-b-2 border-[#DD727D];
    }
    /* banner */
    .banner-wrap {
        background-image: url("./HomeBanner/assets/banner.jpg");

        @apply bg-cover relative bg-center;
    }
    .banner-wrap::before {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0.5) 100%
        );
        content: "";
        @apply absolute inset-0 -z-0 lg:rounded-3xl;
    }
    .banner-tab-area {
        @apply pb-[8px] border-b border-[#ECECED];
    }
    .banner-tab-area button {
        @apply h-[20px];
    }
    .banner-tab-area button.active {
        @apply before:w-full before:h-[1px] before:bottom-[-8px] before:left-0 before:bg-[#0189EB] relative before:absolute font-bold text-black;
    }

    /* discover */
    .discover-slider-wrap
        .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
        @apply flex pointer-events-auto mt-[40px] gap-[8px] justify-center relative z-10;
    }
    .discover-slider-wrap .swiper-pagination .swiper-pagination-bullet {
        @apply inline-block !w-[8px] !h-[8px] rounded-full bg-[#F1C4C8] transition-all cursor-pointer pointer-events-auto;
    }

    .discover-slider-wrap
        .swiper-pagination
        .swiper-pagination-bullet.swiper-pagination-bullet-active {
        @apply bg-[#EC6274] !w-[50px] pointer-events-auto;
    }

    /* discover card */
    .discover-card-content-gradient {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    }

    .contact-form {
        box-shadow: 0px 15px 20px 0px rgba(233, 233, 233, 0.25);
    }

    .accordion-main-container.active {
        box-shadow: 0px 10px 20px 0px rgba(236, 236, 236, 0.25);
    }
    .accordion-wrap {
        grid-template-rows: 0fr;
        overflow: hidden;
        transition: all 0.3s;
        opacity: 0;
    }
    .accordion-wrap.open {
        grid-template-rows: 1fr;
        overflow: visible;
        opacity: 1;
    }
    .accordion-wrap.open .accordion-content-wrap {
        @apply mt-[22px]  lg:pb-[40px];
    }
    .recommendation-tabs button {
        @apply transition-all text-[#4E4E4E] text-xs border border-transparent rounded-[100px];
    }
    .recommendation-tabs button.active {
        @apply border-[#EC6274] font-bold text-main-black;
    }

    .checkout-box-wrap {
        border: 1px solid #e3e3e3;
        background: #fff;
        box-shadow: 0px 15px 20px 0px rgba(226, 226, 226, 0.25);
    }
    .checkout-chips button {
        @apply py-[8px] border border-transparent transition-all rounded-[80px] text-xs font-medium text-[#8E8E92] w-full;
    }
    .checkout-chips button.active {
        box-shadow: 0px 2px 10px 0px rgba(210, 210, 210, 0.25);
        @apply border-[#E1E1E1] text-black font-semibold;
    }
}

.custom-scrollbar {
    touch-action: auto;
    cursor: pointer;
    pointer-events: all;
}
/* width */
.custom-scrollbar::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    margin-top: 30px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}
